import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ITrip } from '@app/pages/dashboard/shared/interfaces/trip.interface';
import { AuthService } from '@app/pages/auth/shared/services/auth.service';
import { IPaging } from '@app/shared/interfaces/paging.interface';


@Injectable({
  providedIn: 'root'
})
export class TripService {
  private trips$: BehaviorSubject<ITrip[]>;
  public trips: Observable<ITrip[]>;

  private tripsLoading$: BehaviorSubject<boolean>;
  public tripsLoading: Observable<boolean>;

  private tripsPaging$: BehaviorSubject<IPaging>;
  public tripsPaging: Observable<IPaging>;

  private approveLoading$: BehaviorSubject<boolean>;
  public approveLoading: Observable<boolean>;

  private cancelLoading$: BehaviorSubject<boolean>;
  public cancelLoading: Observable<boolean>;


  constructor(
    private http: HttpClient,
    private userAuthenticationService: AuthService
  ) {
    this.trips$ = new BehaviorSubject<ITrip[]>(null);
    this.trips = this.trips$.asObservable();

    this.tripsLoading$ = new BehaviorSubject<boolean>(false);
    this.tripsLoading = this.tripsLoading$.asObservable();

    this.tripsPaging$ = new BehaviorSubject<IPaging>(null);
    this.tripsPaging = this.tripsPaging$.asObservable();

    this.approveLoading$ = new BehaviorSubject<boolean>(false);
    this.approveLoading = this.approveLoading$.asObservable();

    this.cancelLoading$ = new BehaviorSubject<boolean>(false);
    this.cancelLoading = this.cancelLoading$.asObservable();
  }

  getAll(filterPayload) {
    const currentUser = this.userAuthenticationService.authValue;
    const filterParams = Object.entries(filterPayload).reduce((a, [k, v]) => (v === null ? a : (a[k] = v, a)), {})
    const params = new HttpParams({ fromObject: filterParams }).toString();

    return this.http.post<ITrip[]>(`${environment.apiUrl}/api/v1/operators/${currentUser.operator_id}/quotes/trips?${params}`, {})
      .pipe(
        map((response: any) => {
          if (response) {
            this.trips$.next(response.data);

            const paging: IPaging = {
              ...response.data,
              total_data: response.total_data
            }

            this.tripsPaging$.next(paging);
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  approveBooking(quoteId: number) {
    return this.http.post<ITrip[]>(`${environment.apiUrl}/api/v1/operators/quotes/${quoteId}/approve`, {})
      .pipe(
        map((response: any) => {
          if (response) {
            const trips = this.trips$.value
            if (trips) {
              const position = trips.findIndex((trip) => trip.quote_id == quoteId)
              if (position > -1) {
                trips[position] = {
                  ...trips[position],
                  quote_status: 'approved'
                }

                this.trips$.next(trips);
              }
            }

            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  cancelBooking(quoteId: number) {
    return this.http.post<ITrip[]>(`${environment.apiUrl}/api/v1/operators/quotes/${quoteId}/cancel`, {})
      .pipe(
        map((response: any) => {
          if (response) {
            this.trips$.next(response.data);
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }
}
